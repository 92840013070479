<template>
	<div>
		<div class="card-header d-flex justify-content-between align-items-center py-5">
			<div>
				<h4 class="mb-0">{{ name }}</h4>
				<div v-if="loaded">
					<div>
						<span class="text-dark-50">
							{{ stream.status }} - Viewers:
							{{ viewerCount }}
						</span>
					</div>
					<div></div>
				</div>
			</div>
			<span :id="'info-' + antId" class="svg-icon svg-icon-primary svg-icon-2x clickable" v-b-popover.hover>
				<inline-svg src="/media/svg/icons/Code/info-circle.svg"></inline-svg>
			</span>
		</div>
		<div class="card-body text-center px-2" ref="iframeContainer">
			<iframe
				:width="videoWidth"
				:height="videoHeight"
				:src="`https://streaming.itcglobal.com:5443/RemoteVideo/play.html?name=${antId}&token=${jwt}`"
				frameborder="0"
				allowfullscreen
			></iframe>
		</div>
		<b-popover :target="'info-' + antId" placement="leftbottom" triggers="hover" @show="showStreamInfo()">
			<div class="p-5 text-left" v-if="streamInfo">
				<div>
					<span>Status:</span> <span class="text-dark font-size-lg">{{ stream.status }}</span>
				</div>
				<div>
					<span>Speed:</span> <span class="text-dark font-size-lg">{{ stream.speed }}</span>
				</div>
				<div>
					<span>Video Resolution: </span><span class="text-dark font-size-lg">{{ streamInfo.videoWidth }} x {{ streamInfo.videoHeight }}</span>
				</div>
				<div>
					<span>Bitrate: </span><span class="text-dark font-size-lg">{{ streamInfo.videoBitrate }}</span>
				</div>
				<div>
					<span>Audio Bitrate: </span><span class="text-dark font-size-lg">{{ streamInfo.audioBitrate }}</span>
				</div>
				<div>
					<span>Video Codec: </span><span class="text-dark font-size-lg">{{ streamInfo.videoCodec }}</span>
				</div>
			</div>
			<div v-else-if="loadingInfo">
				<b-spinner></b-spinner>
			</div>
			<div v-else>
				<span class="text-dark font-size-lg">No Info Available</span>
			</div>
		</b-popover>
	</div>
</template>

<script>
export default {
	name: 'VideoPlayer',
	props: {
		antId: {
			type: String,
			required: true,
		},
		jwt: {
			type: String,
			required: true,
		},
		name: {
			type: String,
		},
	},
	data() {
		return {
			loaded: false,
			loadingInfo: false,
			stream: {
				status: null,
				hlsViewerCount: null,
				webRTCViewerCount: null,
				rtmpViewerCount: null,
			},
			streamInfo: null,
			videoWidth: Window.innerWidth - 75,
			videoHeight: this.videoWidth * 0.5625,
		};
	},
	computed: {
		viewerCount() {
			if (this.stream.hlsViewerCount === null && this.stream.webRTCViewerCount === null && this.stream.rtmpViewerCount === null) {
				return 'Unavailable';
			}
			return this.stream.hlsViewerCount + this.stream.webRTCViewerCount + this.stream.rtmpViewerCount;
		},
	},
	methods: {
		showStreamInfo() {
			this.loadingInfo = true;
			this.$http.get(`/stream/${this.antId}/stream-info`).then(({ data }) => {
				this.streamInfo = data[0];
				this.loadingInfo = false;
			});
		},
	},
	created() {
		this.loaded = false;
		this.$http.get(`/stream/${this.antId}/live`).then(({ data }) => {
			this.stream = { ...this.stream, data };
			this.loaded = true;
		});
	},
	mounted() {
		let width = this.$refs.iframeContainer.clientWidth - 30;
		let height = width * 0.5625;
		if (height > Window.innerHeight) {
			this.videoHeight = Window.innerHeight - 75;
			this.videoWidth = this.videoHeight * 1.777;
		} else {
			this.videoWidth = this.$refs.iframeContainer.clientWidth - 30;
			this.videoHeight = this.videoWidth * 0.5625;
		}
	},
};
</script>

<style></style>
